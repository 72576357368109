function getFocusableElements(container) {
  return Array.from(
    container.querySelectorAll(
      "summary, a[href], button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe"
    )
  )
}

function removeTrapFocus(elementToFocus = null) {
  document.removeEventListener('focusin', trapFocusHandlers.focusin)
  document.removeEventListener('focusout', trapFocusHandlers.focusout)
  document.removeEventListener('keydown', trapFocusHandlers.keydown)

  if (elementToFocus) elementToFocus.focus()
}

const trapFocusHandlers = {};

function trapFocus(container, elementToFocus = container, toggleBtn = null) {
	let elements = getFocusableElements(container)
	let first = elements[0]
	let last = elements[elements.length - 1]

	removeTrapFocus();

  trapFocusHandlers.focusin = (event) => {
		document.addEventListener('keydown', trapFocusHandlers.keydown)
  };

	trapFocusHandlers.focusout = function() {
    document.removeEventListener('keydown', trapFocusHandlers.keydown)
  };

	trapFocusHandlers.keydown = function(event) {
		if(event.code.toUpperCase() == 'ESCAPE' && event.target !== toggleBtn) {
			toggleBtn.focus()
		}
    if (event.code.toUpperCase() !== 'TAB') return; // If not TAB key
    // On the last focusable element and tab forward, focus the first element.
    if (event.target === last && !event.shiftKey) {
			event.preventDefault()

			if(toggleBtn) {
				toggleBtn.focus()
			} else {
				first.focus()
			}
      
    }

    //  On the first focusable element and tab backward, focus the last element.
    if (
      (event.target === container || event.target === first) &&
      event.shiftKey
    ) {
      event.preventDefault()

			if(toggleBtn) {
				toggleBtn.focus()
			} else {
				last.focus()
			}

		}

		if(event.target === toggleBtn) {
			event.preventDefault();
			
			if(event.code.toUpperCase() == 'TAB') first.focus()
			if(event.shiftKey) last.focus();
		}
  };

	document.addEventListener('focusout', trapFocusHandlers.focusout)
	document.addEventListener('focusin', trapFocusHandlers.focusin)


	if(toggleBtn) {
		elementToFocus = toggleBtn
		document.addEventListener('keydown', trapFocusHandlers.keydown)
	}

	elementToFocus.focus()
}

function mainMenuAccessibility(menuItemHasChildren) {
	menuItemHasChildren.forEach((item, index) => {
		item.isOpen = false;
		const subMenu = item.querySelector('.sub-menu');
		const subMenuId = `sub-menu-${index + 1}`
		subMenu.id = subMenuId;

		const anchor = item.querySelector('a');
		const btn = document.createElement('button');
		const span = document.createElement('span');

		btn.classList.add('kd-nav__toggle');
		btn.setAttribute('aria-controls', subMenuId)
		btn.setAttribute('aria-haspopup', 'menu')
		btn.appendChild(span);

		anchor.appendChild(btn);

		function clearSubMenuHasChildren(menuItemHasChildren) {
			menuItemHasChildren.forEach(subItem => {
				if(subItem.isOpen) {
					subItem.isOpen = false
					
					const btn = subItem.querySelector('.kd-nav__toggle');
					btn.setAttribute('aria-expanded', false)

					subItem.setAttribute('aria-expanded', false)
					subItem.classList.remove('active')
					item.classList.remove('active')
				}
			});
		}

		item.addEventListener('mouseenter', function(e) {
			const { target } = e;
			if(!item.isOpen) {
				item.isOpen = true
				target.classList.add('active')
				item.setAttribute('aria-expanded', true);
				btn.setAttribute('aria-expanded', true);
			}
		})

		item.addEventListener('mouseleave', function(e) {
			const {target} = e;
			item.isOpen = false
			target.classList.remove('active')
			item.setAttribute('aria-expanded', false)
			btn.setAttribute('aria-expanded', false)

			const menuItemHasChildren = item.querySelectorAll('.menu-item-has-children');
			clearSubMenuHasChildren(menuItemHasChildren);

		})

		btn.addEventListener('click', (e) => {
			e.preventDefault();
			item.isOpen = item.isOpen ? false : true		
			item.classList.toggle('active')
			item.setAttribute('aria-expanded', `${item.isOpen ? 'true' : 'false'}`)
			btn.setAttribute('aria-expanded', `${item.isOpen ? 'true' : 'false'}`);

			const subMenu = item.querySelector('.sub-menu');
			const menuItemHasChildren = subMenu.querySelectorAll('.menu-item-has-children');

			if(!item.isOpen) clearSubMenuHasChildren(menuItemHasChildren)
			
		});
	})
}

function headerNavWidget() {
	const widget = document.querySelector('.kd-widget__header')
	const widgetMenu = widget.querySelector('.menu')
	const widgetSearch = widgetMenu.firstElementChild.firstElementChild

	if(widgetSearch) {
		widgetSearch.textContent = ""
		widgetSearch.setAttribute('aria-label', 'search site')
	}

}

function toggleNav(navContainer, headerEl, toggleBtn, menuItemHasChildren) {
	let isOpen = false;

	return () => {
		isOpen = !isOpen;

		document.body.classList.toggle('kd-nav--active')
		navContainer.classList.toggle('active');
		headerEl.classList.toggle('active')

		toggleBtn.setAttribute('aria-expanded', `${isOpen ? 'true' : false}`)
		trapFocus(navContainer, null, toggleBtn)

		if(!isOpen) removeTrapFocus(toggleBtn);

	}
}

document.addEventListener('DOMContentLoaded', function() {

	const navContainer = document.querySelector('.kd-nav-mobile')
	const headerEl = document.querySelector('.kd-header')
	const toggleBtn = document.querySelector('.kd-nav__button')
	const mobileMenu = document.querySelectorAll('.kd-nav-mobile__menu-items .menu-item a')
	const mobileMenuItemHasChildren = document.querySelectorAll('.kd-nav-mobile .menu-item-has-children')
	const desktopMenuItemHasChildren = document.querySelectorAll('.kd-nav .menu-item-has-children')

	function setTabIndex(Menu, Attribute) {
		for (var i = 0; i < Menu.length; i++) {
			Menu[i][Attribute]('aria-hidden', 'true');
			Menu[i][Attribute]('tabindex', '-1');
		}
	}

	setTabIndex(mobileMenu, 'setAttribute'); 

	const observer = new MutationObserver(function(mutations) {
		mutations.forEach(function(mutationRecord) {

			const sidebarIsActive = mutationRecord.target.classList.contains('kd-nav--active')

			if (!sidebarIsActive) {
				setTabIndex(mobileMenu, 'setAttribute'); 
			} else {
				setTabIndex(mobileMenu, 'removeAttribute'); 
			}

		})
	});
	observer.observe(document.body, { attributes : true, attributeFilter : ['class'] })

	const toggleNavHandler = toggleNav(navContainer, headerEl, toggleBtn, mobileMenuItemHasChildren);

	if(toggleBtn) toggleBtn.addEventListener('click', toggleNavHandler);

	mainMenuAccessibility(desktopMenuItemHasChildren);
	headerNavWidget()
})